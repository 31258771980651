import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { BlogRoll } from './BlogRoll';

export default () => (
  <StaticQuery
    query={graphql`
      query FullBlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                author {
                  frontmatter {
                    author_id
                  }
                }
                image {
                  childImageSharp {
                    gatsbyImageData(
                      height: 400
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
                minutes
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => {
      return <BlogRoll data={data} count={count} />;
    }}
  />
);
