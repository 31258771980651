import { Link, StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './BlogRoll.module.scss';
import Title from './Title';

export class BlogRoll extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    const blogClassName = [
      styles.blog,
      'is-parent column is-6-big-mobile is-6-tablet is-4-desktop',
    ].join(' ');

    return (
      <div
        className="columns is-multiline is-big-mobile"
        style={{ margin: '-2.5%' }}
      >
        {posts &&
          posts.map(({ node: post }) => (
            <div className={blogClassName} key={post.id}>
              <Link
                className={styles.blogLink}
                to={post.fields.slug}
                title={post.frontmatter.title}
              >
                <article className="tile is-child">
                  {post.frontmatter.image != null ? (
                    <GatsbyImage
                      image={
                        post.frontmatter.image.childImageSharp.gatsbyImageData
                      }
                      alt="Blog Featured Image"
                      className={styles.image}
                    />
                  ) : (
                    <div className={styles.image} />
                  )}
                  <div className={styles.text}>
                    <div className={styles.minutes}>
                      {post.frontmatter.minutes} min read
                    </div>
                    <Title
                      text={post.frontmatter.title}
                      element="h3"
                      size="4"
                    />
                    <div className={styles.details}>
                      By {post.frontmatter.author.frontmatter.author_id} -{' '}
                      {post.frontmatter.date}
                    </div>
                  </div>
                </article>
              </Link>
            </div>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          limit: 6
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                author {
                  frontmatter {
                    author_id
                  }
                }
                image {
                  childImageSharp {
                    gatsbyImageData(
                      height: 400
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                    )
                  }
                }
                minutes
                templateKey
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
