import React from 'react';

import FullBlogRoll from '../../components/BlogRollFull';
import Layout from '../../components/Layout';
import Title from '../../components/Title';
import * as styles from './BlogIndexPage.module.scss';

export default class BlogIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.popDownNavBar = React.createRef();
  }

  componentDidMount() {
    document.documentElement.classList.toggle('noscroll', false);
  }

  render() {
    return (
      <Layout backdropThree>
        <section className={`section after-nav ${styles.section}`}>
          <div className="lab3-container">
            <div>
              <div className="margin-top-0 ">
                <Title accentBar text="Our Blog" size="1" animated />
              </div>
            </div>
            <FullBlogRoll />
          </div>
        </section>
      </Layout>
    );
  }
}
